import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

export default new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0}
	},
	routes: [{
			path: '/',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/',
				component: () => import('@/views/index'),
				meta: {
					title: '首页',
					index: 0
				}
			}]
		},
		{
			path: '/news',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/news',
				component: () => import('@/views/news'),
				meta: {
					title: '新闻资讯',
					index: 4
				}
			}]
		},
		{
			path: '/news-details',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/news-details',
				component: () => import('@/views/news-details'),
				meta: {
					title: '新闻资讯详情',
					index: 4
				}
			}]
		},
		{
			path:'/tool-details',
			component:()=>import('@/views/wrap'),
			children:[{
				path:'/tool-details',
				component:()=>import('@/views/tool-details'),
				meta:{
					title:'实用工具详情',
					index:1
				}
			}]
		},
		{
			path: '/tools',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/tools',
				component: () => import('@/views/tools'),
				meta: {
					title: '实用工具',
					index: 3
				}
			}]
		},
		{
			path: '/join',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/join',
				component: () => import('@/views/join'),
				meta: {
					title: '公司招聘',
					index: 7
				}
			}]
		},
		{
			path: '/join-details',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/join-details',
				component: () => import('@/views/join-details'),
				meta: {
					title: '公司招聘',
					index: 7
				}
			}]
		},
		{
			path: '/carVideo',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/carVideo',
				component: () => import('@/views/carVideo'),
				meta: {
					title: '直播看车',
					index: 2
				}
			}]
		},
		{
			path: '/feedback',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/feedback',
				component: () => import('@/views/feedback'),
				meta: {
					title: '留言反馈',
					index: 6
				}
			}]
		},
		{
			path: '/contact',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/contact',
				component: () => import('@/views/contact'),
				meta: {
					title: '联系我们',
					index: 5
				}
			}]
		},
		{
			path: '/carTypeIntro',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/carTypeIntro',
				component: () => import('@/views/carTypeIntro'),
				meta: {
					title: '车型列表',
					index: 1
				}
			}]
		},
		{
			path: '/carType-details',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/carType-details',
				component: () => import('@/views/carType-details'),
				meta: {
					title: '车型列表',
					index: 1
				}
			}]
		},
		{
			path: '/carType-video-list',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/carType-video-list',
				component: () => import('@/views/carType-video-list'),
				meta: {
					title: '车型视频',
					index: 1,
					isShowNav: false
				}
			}]
		},
		{
			path: '/carType-GNvideo-list',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/carType-GNvideo-list',
				component: () => import('@/views/carType-GNvideo-list'),
				meta: {
					title: '车型视频',
					index: 1,
					isShowNav: false
				}
			}]
		},
		{
			path: '/about',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/about',
				component: () => import('@/views/about'),
				meta: {
					title: '关于我们',
					index: '',
				}
			}]
		},
		{
			path: '/about-details',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/about-details',
				component: () => import('@/views/about-details'),
				meta: {
					title: '关于我们',
					index: '',
				}
			}]
		},
		{
			path: '/carTypeVs',
			component: () => import('@/views/wrap'),
			children: [{
				path: '/carTypeVs',
				component: () => import('@/views/carTypeVs'),
				meta: {
					title: '车型比对',
					index: '',
					isShowNav: false
				}
			}]
		}
	]
})

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
