<template>
	<div class="ft20">
		<van-sticky :offset-top="'1rem'" z-index="5">
			<div class="filter-bar">
				<div class="van-hairline--bottom ft26 pb30 pl30 pr30 pt30">
					<van-row>
						<van-col :span="5" @click="handleDefaultSort">
							<span>综合排序</span>
						</van-col>
						<van-col :span="10" style="padding-left:0.9rem;">
							<div class="hover flex" @click="changeFilter('selectBrandStatus')">
								<span class="mr14">品牌</span>
								<img src="../assets/arrow-top.png" style="width: .2rem;" v-if="selectBrandStatus" />
								<img src="../assets/arrow-bottom.png" style="width: .2rem;" v-else />
							</div>
						</van-col>
						<van-col :span="9" style="padding-left:0.6rem;">
							<div class="hover flex" @click="changeFilter('selectCountryStatus')">
								<span class="mr14">国别</span>
								<img src="../assets/arrow-top.png" style="width: .2rem;" v-if="selectCountryStatus" />
								<img src="../assets/arrow-bottom.png" style="width: .2rem;" v-else />
							</div>
						</van-col>
						<!--<van-col :span="5">-->
							<!--<div class="hover flex" @click="changeFilter('selectPaiLiangStatus')">-->
								<!--<span class="mr14">排量</span>-->
								<!--<img src="../assets/arrow-top.png" style="width: .2rem;" v-if="selectPaiLiangStatus" />-->
								<!--<img src="../assets/arrow-bottom.png" style="width: .2rem;" v-else />-->
							<!--</div>-->
						<!--</van-col>-->
						<!--<van-col :span="4">-->
							<!--<div class="hover flex" @click="changeFilter('selectColorStatus')">-->
								<!--<span class="mr14">颜色</span>-->
								<!--<img src="../assets/arrow-top.png" style="width: .2rem;" v-if="selectColorStatus" />-->
								<!--<img src="../assets/arrow-bottom.png" style="width: .2rem;" v-else />-->
							<!--</div>-->
						<!--</van-col>-->
					</van-row>
				</div>
				<div class="el-box">
					<!-- 品牌 -->
					<div class="brand-box" v-if="selectBrandStatus">
						<div class="ft20" v-if="brandStatus">
							<div class="title pt20  pl30 pr30 van-hairline--bottom pb20" @click="handleCancle">不限</div>
							<div v-for="(item,index) in brandList" :key="index" @click="handleBrand(item.code, item.name)"  class="title flex pt20 over hover pl30 pr30 van-hairline--bottom pb20 over">
								<van-image style="width: .58rem;display: inline-block;vertical-align: middle;float: left;" fit="cover" :src="item.icon"></van-image>
								<span class="ml30 fl">{{item.name}}</span>
								<van-icon name="arrow" class="mla"></van-icon>
							</div>
						</div>
						<div class="ft20" v-else>
							<div class="title pt20 ft26 pl30 pr30 el-border-bottom pb20 hover" @click="brandStatus=true">
								<van-icon name="arrow-left" class="mla"></van-icon>
								<span class="ml20">{{brandName}} - </span>
								<span class="red">{{seriesName}}</span>
							</div>
							<div v-for="(item,index) in seriesList" :key="index" @click="handleSeries(item.code, item.name)" class="title ft26 flex pt20 hover pl30 pr30 van-hairline--bottom pb20 over">
								<span class="fl">{{item.name}}</span>
								<van-icon name="arrow" class="mla"></van-icon>
							</div>
						</div>
					</div>
					<!-- 国别 -->
					<div class="brand-box filer-tag" v-if="selectCountryStatus">
						<div class="ft20 pl16 pr16 over pb30">
							<van-row :gutter="10">
								<van-col :span="6" v-for="(item,index) in countryValue" :key="index">
									<div @click="selectCountryChange(index)" :class="item.check?'selected':''" class="item ft26 flex hover">
										{{item.name}}
									</div>
								</van-col>
							</van-row>
							<div class="btn hover" @click="handleCountrySubmit">确定</div>
						</div>
					</div>
					<!-- 排量 -->
					<div class="brand-box filer-tag" v-if="selectPaiLiangStatus">
						<div class="ft20 pl16 pr16 over pb30">
							<van-row :gutter="10">
								<van-col :span="6" v-for="(item,index) in paiLingValue" :key="index">
									<div @click="selectPaiLiangChange(index)" :class="item.check?'selected':''" class="item ft26 flex hover">
										{{item.name}}
									</div>
								</van-col>
							</van-row>
							<div class="btn hover" @click="selectPaiLiangStatus=false">确定</div>
						</div>
					</div>
					<!-- 颜色 -->
					<div class="brand-box filer-tag" v-if="selectColorStatus">
						<div class="ft20 pl16 pr16 over pb30">
							<van-row :gutter="10">
								<van-col :span="6" v-for="(item,index) in colorValue" :key="index">
									<div @click="selectColorChange(index)" :class="item.check?'selected':''" class="item flex ft26 hover">
										{{item.name}}
									</div>
								</van-col>
							</van-row>
							<div class="btn hover" @click="selectColorStatus=false">确定</div>
						</div>
					</div>
				</div>
			</div>
		</van-sticky>
		<div class="filter-bg" @click="changeFilter" v-if="selectBrandStatus || selectCountryStatus || selectPaiLiangStatus || selectColorStatus"></div>
	</div>
</template>

<script>
	import { findCountry, findBrand, findSeries} from '@/api/index.js'
	export default{
		data(){
			return{
				brandStatus:true,
				selectBrandStatus:false,
				selectCountryStatus:false,
				countryValue:[
					{
						code: '',
						name: '不限',
						check:false
					}
				],
				brandName: '',
				seriesName: '',
				brandList: [],
				seriesList: [],
				selectPaiLiangStatus:false,
				paiLingValue:[{name:'3956cc',check:false},{name:'3956cc',check:false},{name:'3956cc',check:false},{name:'3956cc',check:false},{name:'3956cc',check:false},{name:'3956cc',check:false},{name:'3956cc',check:false}],
				selectColorStatus:false,
				colorValue:[{name:'红色',check:false},{name:'红色',check:false},{name:'红色',check:false},{name:'红色',check:false},{name:'红色',check:false},{name:'红色',check:false},{name:'红色',check:false}],
			}
		},
		created(){
			this.getBrandList()
			this.getCountryList()
		},
		methods:{
			handleDefaultSort(){
				this.countryValue.forEach(item=>{
					item.check = false
				})
				this.selectCountryStatus=false
				this.brandName = ''
				this.seriesName = ''
				this.brandStatus = true
				this.$emit('update:series','')
				this.$emit('update:countryCode',[])
				this.$emit('refreshDataList')
			},
			selectPaiLiangChange(index){
				this.paiLingValue[index].check = !this.paiLingValue[index].check;
			},
			selectColorChange(index){
				this.colorValue[index].check = !this.colorValue[index].check;
			},
			selectCountryChange(index){
				if(index == 0){
					this.countryValue.forEach((item,idx)=>{
						if(idx > 0){
							this.countryValue[idx].check = false
						}
					})
				}
				this.countryValue[index].check = !this.countryValue[index].check;
			},
			changeFilter(tag){
				this.selectBrandStatus = false;
				this.selectCountryStatus = false;
				// this.selectPaiLiangStatus = false;
				// this.selectColorStatus = false;
				this[tag]=!this[tag]
			},


			getCountryList(){
				findCountry().then(res=>{
					if(res.code === 200){
						res.data.forEach(item=>{
							this.countryValue.push({
								code: item.code,
								name: item.name,
								check:false
							})
						})
					}
				})
			},

			getBrandList(){
				findBrand().then(res=>{
					if(res.code === 200){
						this.brandList = res.data;
					}
				})
			},

			handleBrand(code, name){
				this.brandStatus=false
				this.brandName = name
				this.getSeriesList(code)
			},
			handleCancle(){
				this.$emit('update:series','')
				this.$emit('refreshDataList')
				this.selectBrandStatus=false
				this.brandStatus=false
			},
			getSeriesList(code){
				findSeries(code).then(res=>{
					if(res.code === 200){
						this.seriesList = res.data;
						if(this.seriesList && this.seriesList.length > 0){
							this.seriesName = this.seriesList[0].name
						}
					}
				})
			},

			handleCountrySubmit(){
				let countryCode = []
				this.countryValue.forEach(item=>{
					if(item.check && item.code != ''){
						countryCode.push(item.code)
					}
				})
				if(countryCode && countryCode.length > 0){
					this.$emit('update:countryCode',countryCode)
				}else{
					this.$emit('update:countryCode',[])
				}
				this.$emit('refreshDataList')
				this.selectCountryStatus=false
			},

			handleSeries(code, name){
				this.seriesName = name;
				this.$emit('update:series',code)
				this.$emit('refreshDataList')
				this.selectBrandStatus=false
			},
		}
	}
</script>

<style scoped="scoped">
	.el-box{
		max-height: 7rem;
		overflow-y: auto;
	}
	.filter-bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 4;
		height: 100%;
		background-color: rgba(0, 0, 0, .5);
	}
	.filter-bar{
		width: 92%;
		box-sizing: border-box;
		background-color: #FFFFFF;
		border-radius: .1rem;
		border: 1px solid #F1F1F1;
		z-index: 5;
		overflow: hidden;
		margin-left: 4%;
		position: relative;
	}
	.filer-tag .item{
		padding: .2rem 0px;
		border-radius: .1rem;
		background-color: #f3f3f3;
		float: left;
		margin: .2rem 0px 0;
		width: 100%;
		text-align: center;
		display: block;
	}
	.filer-tag .item.selected{
		background-color:#f0d3d5 ;
	}
	.btn{
		margin: .5rem auto 0;
		line-height: .6rem;
		font-size: .28rem;
		text-align: center;
		border-radius: 100px;
		background-color: #B4232F;
		color: #FFFFFF;
	}
</style>
