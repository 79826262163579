import request from '@/assets/utils/request'

/**
 * 新闻分页查询
 * @param data
 */
export function pageList(data) {
  return request({
    url: '/news/pageList',
    method: 'post',
    data: data
  })
}

/**
 * 汽车基础信息分页查询
 * @param data
 */
export function pageCarList(data) {
  return request({
    url: '/carInfo/pageList',
    method: 'post',
    data: data
  })
}

/**
 * 获取所有轮播图
 * @param id
 */
export function bannerList() {
  return request({
    url: '/banner/find',
    method: 'get'
  })
}

/**
 * 关于我们
 */
export function getAboutUs() {
  return request({
    url: '/comInfo/find',
    method: 'get'
  })
}

/**
 * 荣誉资质
 */
export function getHonor() {
  return request({
    url: '/honor/find',
    method: 'get'
  })
}

/**
 * 团队风采
 */
export function getTeam() {
  return request({
    url: '/team/find',
    method: 'get'
  })
}

/**
 * 车型详情
 */
export function carDetails(id) {
  return request({
    url: `/carInfo/getCarDetails/${id}`,
    method: 'get'
  })
}

/**
 * 新闻列表详情
 */
export function getNews(data) {
  return request({
    url: '/news/pageList',
    method: 'post',
    data: data
  })
}

/**
 * 新闻详情
 */
export function newsDetails(id) {
  return request({
    url: `/news/details/${id}`,
    method: 'get'
  })
}

/**
 * 实用工具列表详情
 */
export function toolPageList(data) {
  return request({
    url: '/tool/pageList',
    method: 'post',
    data: data
  })
}

/**
 * 实用工具详情
 */
export function toolDetails(id) {
  return request({
    url: `/tool/info/${id}`,
    method: 'get'
  })
}

/**
 * 联系人信息
 */
export function contactInfo() {
  return request({
    url: `/comInfo/contactInfo`,
    method: 'get'
  })
}

/**
 * 提交反馈信息
 */
export function saveFeedback(data) {
  return request({
    url: '/feedback/save',
    method: 'post',
    data: data
  })
}

/**
 * 人才招聘列表
 */
export function recruitPageList(data) {
  return request({
    url: '/recruit/pageList',
    method: 'post',
    data: data
  })
}

/**
 * 招聘详情
 */
export function recruitDetails(id) {
  return request({
    url: `/recruit/info/${id}`,
    method: 'get'
  })
}

/**
 * 获取国别信息
 */
export function findCountry() {
  return request({
    url: `/country/find`,
    method: 'get'
  })
}

/**
 * 获取品牌信息
 */
export function findBrand() {
  return request({
    url: `/brand/find`,
    method: 'get'
  })
}

/**
 * 获取品牌-系列信息
 */
export function findSeries(brandCode) {
  return request({
    url: `/series/find/${brandCode}`,
    method: 'get'
  })
}

/**
 * 保持对比车型
 */
export function saveCompareCar(data) {
  return request({
    url: '/carCompare/save',
    method: 'post',
    data: data
  })
}

/**
 * 对比车型信息
 */
export function carCompareList(compareCode) {
  return request({
    url: `/carCompare/findByCode/${compareCode}`,
    method: 'get'
  })
}

/**
 * 获取对比车型唯一编码
 * @param compareCode
 */
export function getCompareCode() {
  return request({
    url: `/carCompare/generate`,
    method: 'get'
  })
}

/**
 * 更多视频
 */
export function getMoreVideo(code) {
  return request({
    url: `/video/getByInfoCode/${code}`,
    method: 'get'
  })
}

/**
 * 功能视频
 * @param code
 */
export function getFuncVideo(code) {
  return request({
    url: `/video/getFuncByInfoCode/${code}`,
    method: 'get'
  })
}


/**
 * 删除对比车型信息
 * @param code
 */
export function delCompareCar(code) {
  return request({
    url: `/carCompare/del/${code}`,
    method: 'delete'
  })
}

/**
 *
 * @param code
 */
export function getByInfoCode(code) {
  return request({
    url: `/carInfo/getByCode/${code}`,
    method: 'get'
  })
}

export function saveVisitCar(data) {
  return request({
    url: '/visitCustomer/visitCar',
    method: 'post',
    data: data
  })
}

export function updateDruation(id) {
  return request({
    url: `/visitCustomer/visitDuration/${id}`,
    method: 'get'
  })
}





